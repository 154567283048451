var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row mb-2"},[_vm._m(0),_c('div',{staticClass:"col-md text-right"},[(_vm.isLoggedIn())?_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.daysback(-3)}}},[_vm._v("-3 Tage")])]},proxy:true}],null,false,1880853377)},[_c('b-form-datepicker',{attrs:{"locale":"de"},on:{"input":_vm.select},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1):_c('b-form-datepicker',{attrs:{"locale":"de"},on:{"input":_vm.select},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)]),(_vm.hasRoles(['ROLE_ADMIN']) || _vm.comment)?_c('div',{staticClass:"row mb-2 comment"},[_c('div',{staticClass:"col-md-10"},[(_vm.editComment)?_c('ckeditor',{staticClass:"mb-4",attrs:{"id":"comment","disabled":!_vm.hasRoles(['ROLE_ADMIN']),"editor":_vm.editor,"config":_vm.editorConfig()},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}}):_c('span',{domProps:{"innerHTML":_vm._s(_vm.comment)}})],1),(_vm.hasRoles(['ROLE_ADMIN']))?_c('div',{staticClass:"col-md text-right"},[(_vm.editComment)?_c('b-button',{attrs:{"variant":"outline","size":"sm"},on:{"click":function($event){_vm.editComment = false;
          _vm.loadMessage();}}},[_c('b-icon',{attrs:{"icon":"x-square","font-scale":"1.5"}})],1):_vm._e(),(_vm.editComment)?_c('b-button',{attrs:{"variant":"outline","size":"sm"},on:{"click":function($event){_vm.editComment = false;
          _vm.saveMessage();}}},[_c('b-icon',{attrs:{"icon":"check-square","font-scale":"1.5"}})],1):_c('b-button',{attrs:{"variant":"outline","size":"sm"},on:{"click":function($event){_vm.editComment = !_vm.editComment}}},[_c('b-icon',{attrs:{"icon":"pencil-square","font-scale":"1.5"}})],1)],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md d-none d-md-block"},[_c('table',{staticClass:"timetable"},_vm._l((_vm.timetable),function(v,i){return _c('tr',{key:i},[(v.type === 'day')?_c('td',{attrs:{"colspan":"5"}},[_c('div',{staticClass:"timetableday"},[_vm._v(" "+_vm._s(_vm.date(v.item.date))),(v.item.name)?_c('span',[_vm._v(", "+_vm._s(v.item.name))]):_vm._e()])]):_vm._e(),(v.type === 'description')?_c('td',{attrs:{"colspan":"2"}}):_vm._e(),(v.type === 'description')?_c('td',[_c('div',{staticClass:"eventdescription"},[_vm._v(" "+_vm._s(v.item.externalDescription)+" ")])]):_vm._e(),(v.type === 'event')?_c('td',[_c('div',{staticClass:"timetableevent"},[_vm._v(_vm._s(v.item.event.location.name))])]):_vm._e(),(v.type === 'event')?_c('td',{class:v.item.startTime !== v.item.event.startTime
                ? 'startTimeChanged'
                : ''},[_vm._v(" "+_vm._s(v.item.startTime)),(v.item.endTime)?_c('span',[_vm._v("-"+_vm._s(v.item.endTime))]):_vm._e(),_vm._v(" Uhr ")]):_vm._e(),(v.type === 'event')?_c('td',[_c('span',{class:['CANCELLED', 'HIDDEN'].includes(v.item.status)
                  ? 'eventname_cancelled'
                  : 'eventname'},[_vm._v(_vm._s(v.item.name))]),_vm._v(" "),(_vm.isLoggedIn() && v.item.staffDisplay)?_c('span',{class:['CANCELLED', 'HIDDEN'].includes(v.item.status)
                  ? 'small eventname_cancelled'
                  : 'small eventname'},[_vm._v("("+_vm._s(v.item.staffDisplay)+")")]):_vm._e()]):_vm._e(),(v.type === 'event')?_c('td',[(
                !_vm.canEdit() &&
                v.item.event.status === 'RESERVATION' &&
                v.item.registration === true &&
                v.item.status !== 'CANCELLED' &&
                v.item.status !== 'HIDDEN' &&
                v.item.registrationMode !== 'NONE'
              )?_c('span',[(v.item.seatsAvailable > 0)?_c('span',[_c('router-link',{attrs:{"to":{
                    name: 'registration-event',
                    params: { event: v.item.event.uuid },
                    query: { date: _vm.date2(v.parent.date) },
                  }}},[(v.item.currentUser)?_c('span',[_vm._v("Bearbeiten"),_c('b-icon',{staticClass:"ml-2",attrs:{"icon":"credit-card-fill"}})],1):_c('span',[_vm._v("Anmelden")])]),_vm._v(" ("+_vm._s(v.item.seatsAvailable)+" freie Plätze)")],1):_c('span',[_vm._v("Keine freien Plätze")])]):(
                !_vm.isLoggedIn() &&
                ['CANCELLED', 'HIDDEN'].includes(v.item.status)
              )?_c('span',[_vm._v("Fällt aus")]):(!_vm.isLoggedIn() && v.item.registrationMode === 'NONE')?_c('span',{staticClass:"small"},[_vm._v("Keine Anmeldung erforderlich")]):(!_vm.isLoggedIn())?_c('span',{staticClass:"small"},[_vm._v("Anmeldung nicht möglich ("+_vm._s(v.item.seatsAvailable > 0 ? v.item.seatsAvailable : 0)+" freie Plätze)")]):_vm._e()]):_vm._e(),(v.type === 'event')?_c('td',[(_vm.canEdit())?_c('router-link',{attrs:{"to":{
                name: 'report',
                params: { event: v.item.event.uuid },
                query: { date: _vm.date2(v.parent.date) },
              }}},[_vm._v("Bearbeiten")]):_vm._e(),(
                _vm.canEdit() &&
                v.item.status !== 'CANCELLED' &&
                v.item.status !== 'HIDDEN'
              )?_c('span',[(v.item.seatsAvailable > 0)?_c('span',[_vm._v(" ("+_vm._s(v.item.seatsAvailable)+" freie Plätze)")]):_c('span',[_vm._v(" (Keine freien Plätze)")])]):_vm._e()],1):_vm._e()])}),0)]),_c('div',{staticClass:"col-md d-md-none"},[_c('table',{staticClass:"timetable"},_vm._l((_vm.timetable),function(v,i){return _c('tr',{key:i},[(v.type === 'day')?_c('td',{attrs:{"colspan":"2"}},[_c('div',{staticClass:"timetableday"},[_vm._v(" "+_vm._s(_vm.date(v.item.date))+" "),(v.item.name)?_c('span',[_vm._v(", "+_vm._s(v.item.name))]):_vm._e()])]):_vm._e(),(v.type === 'description')?_c('td',[_c('div',{staticClass:"eventdescription"},[_vm._v(" "+_vm._s(v.item.externalDescription)+" ")])]):_vm._e(),(v.type === 'event')?_c('td',[_c('div',{class:['CANCELLED', 'HIDDEN'].includes(v.item.status)
                  ? 'eventname_cancelled'
                  : 'eventname'},[_vm._v(" "+_vm._s(v.item.event.location.name)),_c('br'),_c('span',{class:v.item.startTime !== v.item.event.startTime
                    ? 'startTimeChanged'
                    : ''},[_vm._v(_vm._s(v.item.startTime)),(v.item.endTime)?_c('span',[_vm._v("-"+_vm._s(v.item.endTime))]):_vm._e()]),_vm._v(" "),_c('span',[_vm._v(_vm._s(v.item.name))]),_vm._v(" "),(_vm.isLoggedIn() && v.item.staffDisplay)?_c('span',[_vm._v("("+_vm._s(v.item.staffDisplay)+")")]):_vm._e()])]):_vm._e(),(v.type === 'event')?_c('td',[(
                !_vm.canEdit() &&
                v.item.event.status === 'RESERVATION' &&
                v.item.registration === true &&
                v.item.status !== 'CANCELLED' &&
                v.item.status !== 'HIDDEN' &&
                v.item.registrationMode !== 'NONE'
              )?_c('span',[(v.item.seatsAvailable > 0)?_c('span',[_c('router-link',{attrs:{"to":{
                    name: 'registration-event',
                    params: { event: v.item.event.uuid },
                    query: { date: _vm.date2(v.parent.date) },
                  }}},[(v.item.currentUser)?_c('span',[_vm._v("Bearbeiten"),_c('b-icon',{staticClass:"ml-2",attrs:{"icon":"credit-card-fill"}})],1):_c('span',[_vm._v("Anmelden")])]),_c('br'),_c('span',{staticClass:"small"},[_vm._v("("+_vm._s(v.item.seatsAvailable)+" freie Plätze)")])],1):_c('span',{staticClass:"small"},[_vm._v("Keine freien Plätze")])]):(
                !_vm.isLoggedIn() &&
                ['CANCELLED', 'HIDDEN'].includes(v.item.status)
              )?_c('span',[_vm._v("Fällt aus")]):(!_vm.isLoggedIn() && v.item.registrationMode === 'NONE')?_c('span',{staticClass:"small"},[_vm._v("Keine Anmeldung erforderlich")]):(!_vm.isLoggedIn())?_c('span',{staticClass:"small"},[_vm._v("Anmeldung nicht möglich ("+_vm._s(v.item.seatsAvailable > 0 ? v.item.seatsAvailable : 0)+" freie Plätze)")]):_vm._e(),(_vm.canEdit())?_c('router-link',{attrs:{"to":{
                name: 'report',
                params: { event: v.item.event.uuid },
                query: { date: _vm.date2(v.parent.date) },
              }}},[_vm._v("Bearbeiten")]):_vm._e(),_c('br'),(
                _vm.canEdit() &&
                v.item.status !== 'CANCELLED' &&
                v.item.status !== 'HIDDEN'
              )?_c('span',[(v.item.seatsAvailable > 0)?_c('span',{staticClass:"small"},[_vm._v(" ("+_vm._s(v.item.seatsAvailable)+" freie Plätze)")]):_c('span',{staticClass:"small"},[_vm._v(" (Keine freien Plätze)")])]):_vm._e()],1):_vm._e()])}),0)])]),_c('div',{staticClass:"row mt-4 mb-4"},[_c('div',{staticClass:"col-md text-right"},[_c('b-button',{staticClass:"mr-2",attrs:{"id":"back","variant":"outline-secondary"},on:{"click":function($event){return _vm.forward(-_vm.days)}}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"arrow-left"}}),_vm._v("Zurück")],1),_c('b-button',{attrs:{"id":"forward","variant":"outline-primary"},on:{"click":function($event){return _vm.forward(_vm.days)}}},[_vm._v("Weiter"),_c('b-icon',{staticClass:"ml-2",attrs:{"icon":"arrow-right"}})],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-8"},[_c('h4',[_vm._v("Gottesdienste und Veranstaltungen")])])}]

export { render, staticRenderFns }