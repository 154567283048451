




























































































































































































































































































































import TimetableService from "@/services/TimetableService";
import AuthService from "../services/AuthService";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";
import "moment/locale/de";
import { Component, Vue } from "vue-property-decorator";
import router from "../router/index";

@Component
export default class Home extends Vue {
  private editor = ClassicEditor;
  private messageUuid: string = "e75b21cb-00ad-4ce5-ad9c-d8497297f765";
  private timetable: any[] = [];
  private locationUuid: string = router.currentRoute.query.location as string;
  private startDate: string =
    (router.currentRoute.query.startDate as string) || this.date2(new Date());
  private days: number = 14;
  private comment: string = "";
  private editComment: boolean = false;

  daysback(inc: number) {
    this.startDate = this.date2(
      new Date(Date.parse(this.startDate) + 86400 * 1000 * inc)
    );
    this.reload(
      (Date.parse(this.startDate) - Date.parse(this.date2(new Date()))) /
        (1000 * 3600 * 24),
      this.days,
      this.locationUuid
    );
    router
      .replace({
        name: "home",
        query: { startDate: this.date2(this.startDate) },
      })
      .catch((e) => {});
  }

  forward(days: number) {
    this.startDate = this.date2(
      Date.parse(this.startDate) + 1000 * 3600 * 24 * days
    );
    this.reload(
      (Date.parse(this.startDate) - Date.parse(this.date2(new Date()))) /
        (1000 * 3600 * 24),
      this.days,
      this.locationUuid
    );
    router
      .replace({
        name: "home",
        query: { startDate: this.date2(this.startDate) },
      })
      .catch((e) => {});
  }

  select() {
    this.reload(
      (Date.parse(this.startDate) - Date.parse(this.date2(new Date()))) /
        (1000 * 3600 * 24),
      this.days,
      this.locationUuid
    );
    router
      .replace({
        name: "home",
        query: { startDate: this.date2(this.startDate) },
      })
      .catch((e) => {});
  }

  isLoggedIn() {
    return (
      AuthService.isLoggedIn() &&
      this.canEdit()
    );
  }

  hasRoles(roles: any[]) {
    return AuthService.hasRoles(roles);
  }

  canEdit() {
    return this.hasRoles(["ROLE_ADMIN", "ROLE_OFFICE"]);
  }

  date(date: any) {
    return moment(date).format("dddd, DD.MM.YYYY");
  }

  date2(date: any) {
    return moment(date).format("YYYY-MM-DD");
  }

  saveMessage() {
    TimetableService.saveMessage(this.messageUuid, this.comment).then(
      (response) => (this.comment = response.data)
    );
  }

  loadMessage() {
    TimetableService.loadMessage(this.messageUuid).then(
      (response) => (this.comment = response.data)
    );
  }

  reload(start: number, count: number, locationUuid: string) {
    this.timetable = [];

    this.loadMessage();

    TimetableService.timetable(start, count, locationUuid).then((response) => {
      this.startDate = response.data[0].date;
      response.data
        .filter((e: any) => e.events.length > 0 || e.name)
        .forEach((r: any) => {
          this.timetable.push({ type: "day", item: r });
          r.events.forEach((i: any) => {
            this.timetable.push({ type: "event", parent: r, item: i });
            if (i.externalDescription) {
              this.timetable.push({ type: "description", parent: r, item: i });
            }
          });
        });
    });
  }

  mounted() {
    moment.locale("de");
    moment.weekdays(true);

    this.reload(
      (Date.parse(this.startDate) - Date.parse(this.date2(new Date()))) /
        (1000 * 3600 * 24),
      this.days,
      this.locationUuid
    );
  }
}
